<template>
  <header class="Header">
    <div class="top">
      <div class="name w-6">{{ statusPage.title || 'Status Page' }}</div>
    </div>

    <header-navigation :status-page="statusPage" @refreshStatusPage="refreshStatusPage"/>
  </header>
</template>

<script>
import HeaderNavigation from './HeaderNavigation.vue'

export default {
  components: {
    HeaderNavigation
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    }
  },

  methods: {
    refreshStatusPage () {
      this.$emit('refreshStatusPage')
    }
  }
}
</script>

<style lang="scss" scoped>
  .Header {
    margin-bottom: 20px;
    border-bottom: 1px solid #dfe2e4;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      margin-bottom: 10px;
    }

    .name {
      font-size: 24px;
    }
  }
</style>
